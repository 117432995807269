body {
  font-family: 'Trebuchet MS', sans-serif;
  margin: 0;
  padding: 0;
  color: #374881;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #374881;
  color: white;
}

header img {
  height: 50px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav ul li {
  cursor: pointer;
}

nav ul li a {
  color: #F07F5D;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.logo {
  max-width: 400px;
  height: auto;
}

section {
  padding: 40px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

h3 {
  color: #F07F5D;
}

h4 {
  font-size: 26px;
  color: #7E4E73;
  display: flex;
  align-items: center;
  cursor: pointer;
}

h4 svg {
  margin-left: 8px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

a {
  color: #EA5347;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #374881;
  color: white;
}

.leaflet-container {
  width: 100%;
  height: 500px;
}

@media (max-width: 768px) {
  header, section {
    padding: 20px;
  }

  nav ul {
    flex-direction: column;
    gap: 10px;
  }
}
